.file-wrapper {
  background: #ececec;
}
.uploader-input{
  opacity: 0;
}

.file-uploader {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  border: 2px dashed #d6d6d6;
  border-radius: 5px;
  font-weight: 300;
  color: #d6d6d6;
  cursor: pointer;
}

.document-uploader {
  background: #fafafa;
  color: #c1c1c1;
}
.file-uploader.active,
.file-uploader-hover:hover {
  border-color: #99c7ff;
}

.upload-server {
  /*background: #76838f;*/
  color: #fff;
  padding: 8px 12px;
  border-radius: 5px;
  /*transition: background 0.2s;*/
}

/*.upload-server:hover{*/
/*    background: #626f7b;*/
/*}*/
.remove-preview {
  position: absolute;
  display: inline-block;
  top: 2px;
  right: 2px;
  background-color: #e66363;
  font-size: 30px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}
