@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    padding:0;
    margin:0;
}

body{
    background: #fff !important;
}
h4{
    font-size: 16px !important;
}
.bg-main{
    background: #3F63F6;
}
.gradient-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40%; /* h-1/3 */
    background: linear-gradient(to top, rgba(85, 88, 104, 0.7), transparent);
    color: white;
    padding: 1rem; /* p-4 */
    border-radius: 0 0 0.5rem 0.5rem; /* rounded-b-lg */
}

.navbar {
    border-bottom: 1px solid #ddd;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo a {
    text-decoration: none;
    color: #333;
    font-size: 1.5rem;
    font-weight: bold;
}

/* Menu */
.menu {
    list-style: none;
    display: flex;
    gap: 30px;
    margin: 0;
}

.menu li {
    position: relative;
    height: 50px;
    line-height: 50px;
}

.menu a {
    text-decoration: none;
    color: #6B7280 !important;
    font-size: 1rem;
    font-weight: 500;
    transition: color 0.3s;
    padding: 10px 0;
}

.menu li a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: #3F63F6 !important;
    transition: width 0.3s ease-in-out !important;
}

.menu li a:hover::after {
    width: 100%;
}

.menu a:hover {
    color: #3F63F6 !important;
}

/* Dropdown menyu */
.dropdown-menus {
    position: absolute;
    top: 102%;
    left: 0;
    background-color: #fff;
    list-style: none;
    margin: 0;
    min-width: 280px;
    z-index: 1000;
    padding: 0;
    margin: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.dropdown-menus li {
    height: auto;
    line-height: normal;
    padding: 4px;

}

.dropdown-menus a {
    display: block;
    color: #6B7280;
    transition: all 0.3s;
    position: relative;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    padding: 12px 10px
}

.dropdown-menus a:hover {
    color: #3F63F6;
}

.dropdown-menus a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: #3F63F6;
    transition: width 0.3s ease-in-out;
}

.dropdown-menus a:hover::after {
    width: 100%;
}
.chevron {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-right: 1px solid #333; /* Chevron qirralari */
    border-bottom: 1px solid #333; /* Chevron qirralari */
    transform: rotate(45deg); /* Pastga qaragan uchburchak */
    transition: transform 0.3s ease;
    margin-left: 10px;
    margin-top: 0;
}

.dropdown:hover .chevron {
    /*transform: rotate(225deg); !* Aylangan holatda *!*/
}

/*Main uchun menu style*/

.menu-main {
    list-style: none;
    display: flex;
    gap: 30px;
    margin: 0;
}

.menu-main li {
    position: relative;
    height: 50px;
    line-height: 50px;
}

.menu-main a {
    text-decoration: none;
    color: #fff !important;
    font-size: 1rem;
    font-weight: 500;
    transition: color 0.3s;
    padding: 10px 0;
}

.menu-main li a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: #3F63F6 !important;
    transition: width 0.3s ease-in-out !important;
}

.menu-main li a:hover::after {
    width: 100%;
}

.menu-main a:hover {
    color: #3F63F6 !important;
}

/* Dropdown menyu */
.dropdown-menus-main {
    position: absolute;
    top: 102%;
    left: 0;
    background-color: #fff;
    list-style: none;
    margin: 0;
    min-width: 280px;
    z-index: 1000;
    padding: 0;
    margin: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.dropdown-menus-main li {
    height: auto;
    line-height: normal;
    padding: 4px;

}

.dropdown-menus-main a {
    display: block;
    color: #6B7280 !important;
    transition: all 0.3s;
    position: relative;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    padding: 12px 10px
}

.dropdown-menus-main a:hover {
    color: #3F63F6;
}

.dropdown-menus-main a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: #3F63F6;
    transition: width 0.3s ease-in-out;
}

.dropdown-menus-main a:hover::after {
    width: 100%;
}
.chevron-main {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-right: 1px solid #fff; /* Chevron qirralari */
    border-bottom: 1px solid #fff; /* Chevron qirralari */
    transform: rotate(45deg); /* Pastga qaragan uchburchak */
    transition: transform 0.3s ease;
    margin-left: 10px;
    margin-top: 0;
}

.dropdown:hover .chevron-main {
    /*transform: rotate(225deg); !* Aylangan holatda *!*/
}

/*tugadi */

.sub-menu{
    padding: 0 !important;
    margin: 0;
}
.main_news_list{
    width: 99%;
}
.main_news_list li {
    margin-bottom: 5px;
}
.main_news_list a {
    text-decoration: none;
}
.main_news_list a:hover {
    color: #3F63F6 !important;
}
.middle_bg{
    background: #FAFBFF !important;
}
.main_icon_rounded{
    background: #3F63F6 !important;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border:4px solid #C5D0FF
}
.bg_banner{
    background-image: url("./images/bg_banner_2.png");
    background-size: cover;
    height: 380px;
    width: 100% !important;
}
.info_number::after{
    content: "";
    position: absolute;
    left: 0;
    top: 30px;
    width: 4px;
    height: 40px;
    background: #3F63F6 !important;
    border-radius: 10px;
}
.footer{
    background-image: url("./images/footer_sport.png");
    height: 590px;
    background-repeat: no-repeat;
    background-size: cover;
}
.footer_menu{
    padding:0;
    margin-top: 20px;
}
.footer_menu li{
    color: #fff;
    padding-bottom: 10px;
    display: block;
    width: 50%;
}
.footer_menu li:hover{
    text-decoration: underline;
    cursor: pointer;
}
.footer_bottom{
    margin-top: 30px;
    border-top: 1px solid #fff;
    padding-top: 30px;
}
.sidebar-menu
{

}
.sidebar-menu li{
    border-bottom: 1px solid #ededed;
    padding: 10px 5px;
}
.sidebar-menu li a{
    text-decoration: none;
    color: #999 !important;
}
.sidebar-menu li a:hover{
    color: #3F63F6 !important;
}
.sidebar-menu li a:active{
    color: #3F63F6 !important;
    font-weight: bold;
}
.active{
    color: #3F63F6 !important;
}
.yil{
    position: absolute;
    bottom: 10px;
    background: rgba(19, 42, 99, 0.5);
    backdrop-filter: blur(4px);
    border-radius: 14px;
    text-align: center;
    left: 14px;
    right: 14px;
    padding: 7px 10px;
    color: #fff;
}

.cafedra:hover{
    background: #3F63F6 !important;
}

.header-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Full screen height */
    overflow: hidden;

    color: white;
}

.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures video covers the full area */
    z-index: -2; /* Send video to the back */
}

.gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(28, 30, 35, 0.8), rgba(0, 0, 0, 0.3));
    z-index: -1; /* Gradient is above the video */
}

.header-content {
    z-index: 1;
    position: absolute;
    bottom: 40px;
    width: 100%;
}

.header-content h1 {
    font-size: 3rem;
    margin: 0;
}

.header-content h3 {
    font-size: 1.2rem;
    margin: 0;
}

.header-content p {
    font-size: 1.2rem;
    margin-top: 10px;
}
.divider-top{
    background: transparent;
}
.divider-top::before{
    content: "";
    position: absolute;
    width:1px !important;
    height: 40px !important;
    background: #fff !important;
    right: 50px !important;
    z-index: 99;
    top: 15px !important;

}
label{
    font-size: 13px !important;
}
